import {BaseModelWithName} from "./BaseModelWithName";
import {addDays, addMinutes, addWeeks, isBefore, isSameDay} from "date-fns";
import {DayAvailabilityWithTimes} from "./DayAvailabilityWithTimes";
import {TaxRateModel} from "./TaxRateModel";
import {LocationAvailabilityModel} from "./availability/LocationAvailabilityModel";
import {EnumWebsiteModules} from "./enums/EnumWebsiteModules";
import {HoursAvailabilityModel} from "./availability/HoursAvailabilityModel";
import {isStringNotEmptyOrWhitespace} from "../utils/Utils";
import {EnumDeliveryDelayType} from "./enums/EnumDeliveryDelayType";
import {EnumDayOfWeek} from "./enums/EnumDayOfWeek";
import {EnumOrderType} from "./enums/EnumOrderType";
import {OrderClosureModel} from "./OrderClosureModel";

export class LocationModel extends BaseModelWithName {
  public websiteId: number = 0;
  public street1: Nullable<string> = null;
  public street2: Nullable<string> = null;
  public cityId: number = -1;
  public stateId: number = -1;
  public countyId: number = -1;
  public zip: Nullable<string> = null;
  public identifier: Nullable<string> = null;
  public isTaxRateInheritFromZip: boolean = false;
  public isCustomIdentifier: boolean = false;
  public hours: string = '';

  // extra not mapped properties
  public city: Nullable<string> = null;
  public state: Nullable<string> = null;
  public friendlyName: Nullable<string> = null;
  public contactPhone: Nullable<string> = null;
  public contactEmail: Nullable<string> = null;
  public mapUrl: Nullable<string> = null;
  public imageUrl: Nullable<string> = null;
  public leadTimeForOrders: number = 15;
  public leadTimeForOrdersEnd: number = 20;
  public availabilities: LocationAvailabilityModel = new LocationAvailabilityModel();
  public taxRates: TaxRateModel[] = [];

  public isStripeTest: boolean = false;
  public customFee: number = 0;

  // this is a zone for non-inherited properties
  public daysAvailable: DayAvailabilityWithTimes[] = [];
  public daysAvailableDelivery: DayAvailabilityWithTimes[] = [];
  public stripeAccountId: string = '';
  public allowOrderNow = true;
  public weekEndDay: EnumDayOfWeek = EnumDayOfWeek.Sunday;
  public weekEndHour: number = 0;
  public deliveryDelay: number = 0;
  public deliveryDelayType: EnumDeliveryDelayType = EnumDeliveryDelayType.Minutes;

  public enablePayOnPickup: boolean = true;
  public enablePayWithCreditCard: boolean = true;
  public enableCurbsidePickup: boolean = true;

  public latitute: Nullable<number> = null;
  public longitude: Nullable<number> = null;

  public isOpen24Hours: boolean = false;
  public orderClosuresList: OrderClosureModel[] = [];


  constructor(data: any = null) {
    super(data);

    if (data == null) return;

    if (isStringNotEmptyOrWhitespace(data.street1)) this.street1 = data.street1;
    if (isStringNotEmptyOrWhitespace(data.street2)) this.street2 = data.street2;
    if (isStringNotEmptyOrWhitespace(data.zip)) this.zip = data.zip;
    if (isStringNotEmptyOrWhitespace(data.identifier)) this.identifier = data.identifier;
    if (isStringNotEmptyOrWhitespace(data.city)) this.city = data.city;
    if (isStringNotEmptyOrWhitespace(data.state)) this.state = data.state;
    if (isStringNotEmptyOrWhitespace(data.friendlyName)) this.friendlyName = data.friendlyName;
    if (isStringNotEmptyOrWhitespace(data.hours)) this.hours = data.hours;
    if (isStringNotEmptyOrWhitespace(data.contactPhone)) this.contactPhone = data.contactPhone;
    if (isStringNotEmptyOrWhitespace(data.contactEmail)) this.contactEmail = data.contactEmail;
    if (isStringNotEmptyOrWhitespace(data.mapUrl)) this.mapUrl = data.mapUrl;
    if (isStringNotEmptyOrWhitespace(data.imageUrl)) this.imageUrl = data.imageUrl;

    if (data.isTaxRateInheritFromZip) this.isTaxRateInheritFromZip = true;
    if (data.isCustomIdentifier) this.isCustomIdentifier = true;

    if (data.websiteId != null && !isNaN(parseInt(data.websiteId, 10))) this.websiteId = parseInt(data.websiteId, 10);
    if (data.cityId != null && !isNaN(parseInt(data.cityId, 10))) this.cityId = parseInt(data.cityId, 10);
    if (data.stateId != null && !isNaN(parseInt(data.stateId, 10))) this.stateId = parseInt(data.stateId, 10);
    if (data.countyId != null && !isNaN(parseInt(data.countyId, 10))) this.countyId = parseInt(data.countyId, 10);
    if (data.availabilities != null)
      this.availabilities = new LocationAvailabilityModel(data.availabilities);
    if (data.leadTimeForOrders != null && !isNaN(parseInt(data.leadTimeForOrders, 10)))
      this.leadTimeForOrders = parseInt(data.leadTimeForOrders, 10);
    if (data.leadTimeForOrdersEnd != null && !isNaN(parseInt(data.leadTimeForOrdersEnd, 10)))
      this.leadTimeForOrdersEnd = parseInt(data.leadTimeForOrdersEnd, 10);

    if (data.taxRates != null && Array.isArray(data.taxRates) && data.taxRates.length > 0)
      for (let item of data.taxRates) this.taxRates.push(new TaxRateModel(item));
    if (data.isStripeTest != null)
      this.isStripeTest = data.isStripeTest;
    if (isStringNotEmptyOrWhitespace(data.stripeAccountId))
      this.stripeAccountId = data.stripeAccountId;
    if (data.customFee != null && !isNaN(parseFloat(data.customFee)))
      this.customFee = parseFloat(data.customFee);
    if (data.allowOrderNow != null)
      this.allowOrderNow = data.allowOrderNow;
    if (data.weekEndDay != null && !isNaN(parseInt(data.weekEndDay, 10)))
      this.weekEndDay = parseInt(data.weekEndDay, 10);
    if (data.weekEndHour != null && !isNaN(parseInt(data.weekEndHour, 10)))
      this.weekEndHour = parseInt(data.weekEndHour, 10);
    if (data.deliveryDelay != null && !isNaN(parseInt(data.deliveryDelay, 10)))
      this.deliveryDelay = parseInt(data.deliveryDelay, 10);
    if (data.deliveryDelayType != null && !isNaN(parseInt(data.deliveryDelayType, 10)))
      this.deliveryDelayType = parseInt(data.deliveryDelayType, 10);
    if (data.latitute != null && !isNaN(parseFloat(data.latitute)))
      this.latitute = parseFloat(data.latitute);
    if (data.longitude != null && !isNaN(parseFloat(data.longitude)))
      this.longitude = parseFloat(data.longitude);
    if (data.enablePayOnPickup != null)
      this.enablePayOnPickup = data.enablePayOnPickup;
    if (data.enablePayWithCreditCard != null)
      this.enablePayWithCreditCard = data.enablePayWithCreditCard;
    if (data.enableCurbsidePickup != null)
      this.enableCurbsidePickup = data.enableCurbsidePickup;
    if (data.isOpen24Hours != null)
      this.isOpen24Hours = data.isOpen24Hours;
    if (data.orderClosuresList != null && Array.isArray(data.orderClosuresList) && data.orderClosuresList.length > 0)
      for (let item of data.orderClosuresList) this.orderClosuresList.push(new OrderClosureModel(item));

    this.processDays();
  }

  public taxRate(): number {
    let sum = 0;

    if (this.taxRates.length > 0)
      for (let tax of this.taxRates)
        sum += tax.taxRate;

    return sum;
  }

  public isOpenScheduledOrders(dateAndTime: Date = new Date()) {
    for (let closure of this.orderClosuresList)
      if (closure.isClosed && closure.isBetween(dateAndTime) && !closure.allowScheduledOnlineOrders)
        return false;
    return true;
  }

  public isOpen(module: EnumWebsiteModules, dateAndTime: Date = new Date()): boolean {
    if (!this.isOpenScheduledOrders(dateAndTime))
      return false;

    return this.availabilities.isOpen(module, dateAndTime);
  }

  public getActiveClosure(dateAndTime: Date = new Date()): Nullable<OrderClosureModel> {
    for (let closure of this.orderClosuresList)
      if (closure.isClosed && closure.isBetween(dateAndTime))
        return closure;
    return null;
  }

  public isClosedForOrders(dateAndTime: Date = new Date()):boolean{
    return !this.isOpen(EnumWebsiteModules.OnlineOrder, dateAndTime) && !this.isOpenScheduledOrders(dateAndTime);
  }

  public getFirstAvailableDate(date: Nullable<Date>, orderType: EnumOrderType): Nullable<DayAvailabilityWithTimes> {
    if (date == null)
      return null;

    switch (orderType) {
      case EnumOrderType.PickUp: {
        if (this.daysAvailable != null && this.daysAvailable.length > 0) {
          if (isBefore(date, this.daysAvailable[0].date))
            return this.daysAvailable[0];

          for (let day of this.daysAvailable)
            if (isSameDay(day.date, date))
              return day;

          return this.daysAvailable[0];
        }
        break;
      }
      case EnumOrderType.Delivery: {
        if (this.daysAvailableDelivery != null && this.daysAvailableDelivery.length > 0) {
          if (isBefore(date, this.daysAvailableDelivery[0].date))
            return this.daysAvailableDelivery[0];

          for (let day of this.daysAvailableDelivery)
            if (isSameDay(day.date, date))
              return day;

          return this.daysAvailableDelivery[0];
        }
        break;
      }
    }


    return null;
  }

  public processDays() {
    // const isOpen = this.isOpen();
    const daysInAdvance = 8;

    let dtNow = new Date();
    switch (this.deliveryDelayType) {
      case EnumDeliveryDelayType.Minutes:
        dtNow = addMinutes(dtNow, this.deliveryDelay);
        break;
      case EnumDeliveryDelayType.Hours:
        dtNow = addMinutes(dtNow, this.deliveryDelay * 60);
        break;
      case EnumDeliveryDelayType.Days:
        dtNow = addMinutes(dtNow, this.deliveryDelay * 60 * 24);
        break;
      case EnumDeliveryDelayType.FollowingWeeks: {
        const dayOfWeek = dtNow.getDay();

        // console.log('day of week', dayOfWeek, this.weekEndDay);

        if (dayOfWeek < this.weekEndDay) {
          dtNow = addDays(dtNow, this.weekEndDay - dayOfWeek);
        } else if (dayOfWeek > this.weekEndDay) {
          dtNow = addDays(dtNow, 7 - dayOfWeek + this.weekEndDay);
        } else {
          if (dtNow.getHours() >= this.weekEndHour)
            dtNow = addDays(dtNow, 7);
        }

        const hour = dtNow.getHours();
        if (hour < this.weekEndHour)
          dtNow = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate(), this.weekEndHour, dtNow.getMinutes(), dtNow.getSeconds());


        if (this.deliveryDelay > 1)
          dtNow = addWeeks(dtNow, this.deliveryDelay - 1);

        break;
      }
      default: {
        dtNow = addMinutes(dtNow, this.leadTimeForOrdersEnd);
        break;
      }
    }

    // console.log('dtNow after processDays in LocationModel', dtNow, this.friendlyName);

    const moduleAvailability = this.availabilities.getAvailability(EnumWebsiteModules.OnlineOrder);
    if (moduleAvailability != null) {
      this.daysAvailable = moduleAvailability.getNextDaysAvailable(daysInAdvance, dtNow, this.allowOrderNow ? this.leadTimeForOrdersEnd : 0);
    }

    const moduleAvailabilityDelivery = this.availabilities.getAvailability(EnumWebsiteModules.Delivery);
    if (moduleAvailabilityDelivery != null) {

      this.daysAvailableDelivery = moduleAvailabilityDelivery.getNextDaysAvailable(daysInAdvance, dtNow, this.allowOrderNow ? this.leadTimeForOrdersEnd : 0);
    }
  }

  public getToday(): HoursAvailabilityModel {
    let moduleAvailability = this.availabilities.getAvailability(EnumWebsiteModules.OnlineOrder);
    if (moduleAvailability == null)
      return new HoursAvailabilityModel({isOpen: false});

    return moduleAvailability.getToday();
  }

}


