import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class MenuItemModel {
  public id: number = 0;
  public name: string = '';
  public label: string = '';
  public link: string = '#';
  public sortOrder: number = 0;
  public isHidden: boolean = false;
  public isLocationSpecific: boolean = false;
  public collapsed = false;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.label))
      this.label = data.label;
    if (isStringNotEmptyOrWhitespace(data.link))
      this.link = data.link;
    if (data.sortOrder != null && parseInt(data.sortOrder) > 0)
      this.sortOrder = parseInt(data.sortOrder, 10);
    if (data.isHidden != null && data.isHidden)
      this.isHidden = true;
    if (data.isLocationSpecific != null && data.isLocationSpecific)
      this.isLocationSpecific = true;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (data.id != null && !isNaN(parseInt(data.id)))
      this.id = parseInt(data.id, 10);
  }

}
