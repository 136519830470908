import {SettingsModel} from "./SettingsModel";
import {BaseModelWithName} from "./BaseModelWithName";
import {MenuItemModel} from "./MenuItemModel";
import {LocationModel} from "./LocationModel";
import {WebsitePopupModel} from "./WebsitePopupModel";

export class WebsiteModel extends BaseModelWithName {
  public settings?: SettingsModel;
  public menuItems: MenuItemModel[] = [];
  public locations: LocationModel[] = [];
  public modules: number = 0;
  public websitePopup: Nullable<WebsitePopupModel> = null;

  constructor(data: any = null) {
    super(data);

    if (data == null)
      return;

    if (data.menuItems != null && Array.isArray(data.menuItems) && data.menuItems.length > 0) {
      for (const item of data.menuItems)
        this.menuItems.push(new MenuItemModel(item));
    }

    if (data.locations != null && Array.isArray(data.locations) && data.locations.length > 0) {
      for (const item of data.locations)
        this.locations.push(new LocationModel(item));
    }
    if (data.modules != null && !isNaN(parseInt(data.modules, 10)))
      this.modules = parseInt(data.modules, 10);

    if (data.settings != null)
      this.settings = new SettingsModel(data.settings);

    if (data.websitePopup != null)
      this.websitePopup = new WebsitePopupModel(data.websitePopup);
  }

  getSetting(stringId: string, defaultValue: string = ''): string {

    const tId = stringId?.toLowerCase();
    if (tId == null || tId == '')
      return defaultValue;

    if (this.settings != null && this.settings.items?.length > 0) {
      for (const item of this.settings.items) {
        if (item.stringId?.toLowerCase() === tId)
          return item.value ?? defaultValue;
      }
    }

    return defaultValue;
  }

  findLocation(token: string): Nullable<LocationModel> {
    if (this.locations != null && this.locations.length > 0) {
      for (let item of this.locations)
        if (item.id === token)
          return item;
    }

    return null;
  }

  hasModule(module: number): boolean {
    return (this.modules & module) === module;
  }

  hasPopup(): boolean {
    return this.websitePopup != null;
  }

}

