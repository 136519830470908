import {isAfter, isBefore} from "date-fns";

export class OrderClosureModel {
  public start?: Date;
  public end?: Date;
  public description: string = '';
  public allowScheduledOnlineOrders: boolean = false;
  public isClosed: boolean = false;

  constructor(data: any = null) {
    if (data == null) {
      return;
    }
    if (data.start != null && data.start != '') {
      this.start = new Date(data.start);
    }
    if (data.end != null && data.end != '') {
      this.end = new Date(data.end);
    }
    if (!!data.description) {
      this.description = data.description;
    }
    if (data.allowScheduledOnlineOrders != null) {
      this.allowScheduledOnlineOrders = data.allowScheduledOnlineOrders;
    }
    if (data.isClosed != null) {
      this.isClosed = data.isClosed;
    }
  }

  isValid() {
    return this.start != null && this.end != null;
  }

  isBetween(date: Date) {
    if (this.start == null || this.end == null) {
      return false;
    }

    return isAfter(date, this.start) && isBefore(date, this.end);
  }
}
