import {BaseModelWithNameInt} from "./BaseModelWithNameInt";

export class WebsitePopupModel extends BaseModelWithNameInt {
  public startDate: Nullable<Date> = null;
  public endDate: Nullable<Date> = null;
  public isPublished: boolean = false;
  public websiteId: number = 0;
  public autoClose: boolean = false;
  public description: string = '';
  public embedCode: string = '';

  constructor(data: any = null) {
    super(data);
    if (data == null)
      return;
    if (data.startDate != null && data.startDate != '')
      this.startDate = new Date(data.startDate);
    if (data.endDate != null && data.endDate != '')
      this.endDate = new Date(data.endDate);
    if (data.isPublished != null)
      this.isPublished = data.isPublished;
    if (data.websiteId != null && !isNaN(parseInt(data.websiteId, 10)))
      this.websiteId = data.websiteId;
    if (data.autoClose != null)
      this.autoClose = data.autoClose;
    if (!!data.description)
      this.description = data.description;
    if (!!data.embedCode)
      this.embedCode = data.embedCode;
  }
}
